import axios from "axios";

class Product {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    async getList(
        filters,
        page,
        inputTerm = '1',
        isPreorder = false,
        preorderSeasonId = false,
        familyId = null,
        sort = 'default',
        isWishlist = false,
        extraAttributes = {},
        factory
    ) {
        try {
            //Convert filters to array
            let filtersNormalized = {};

            if(filters) {
                Object.keys(filters).filter(key => {
                    const valuesArray = Array.from(filters[key]);
                    if(valuesArray.length) {

                        //Check for variation filters
                        if( key.includes('sizeCode') || key.includes('sizeName')  || key.includes('cupName') || key.includes('groupcolorname')  ) {
                            key = 'variations.' + key
                        }

                        filtersNormalized[key] = valuesArray
                    }
                })
            }

            if(!page) {
                page = 1
            }

            let products = await axios.post(`${this.apiUrl}/products/list`, {
                filters: filtersNormalized,
                page,
                inputTerm,
                isPreorder,
                preorderSeasonId,
                familyId,
                sort,
                isWishlist,
                extraAttributes,
                factory
            })

            return products.data.payload
        }catch (err) {
            return err
        }
    }

    async getMultiple(productIds, inputDataType = 'id', presentationMode = false) {
        let products = await axios.post(`${this.apiUrl}/products/multiple`, {
            productIds,
            inputDataType,
            cartPresentationMode: presentationMode
        })
        return products.data.payload
    }

    async get(productId, presentationMode = false, cartPresentationMode = false) {
        if(productId) {
            let product = await axios.post(`${this.apiUrl}/products/${productId}`, {
                forcePreorder: presentationMode,
                cartPresentationMode: cartPresentationMode
            })
            return product.data.payload
        }

        let products = await axios.get(`${this.apiUrl}/products`)
        return products.data.payload
    }

    async searchProduct(searchTerm) {
        let products = await axios.post(`${this.apiUrl}/products/search`, {
            searchTerm
        })
        return products.data.payload
    }

    async getPosters() {
        let posters = await axios.get(`${this.apiUrl}/products/type/posters`)
        return posters.data.payload
    }

    async getTopProducts(companyCode, countryCode, seasonCode) {
        let topProducts = await axios.get(`${this.apiUrl}/products/top/${companyCode}/${seasonCode}/${countryCode}`)
        return topProducts.data.payload
    }

    async getSamples() {
        let samples = await axios.get(`${this.apiUrl}/products/samples`)
        return samples.data.payload
    }

    async getIfAnyProduct(){
            let samples = await axios.get(`${this.apiUrl}/products/isNewProduct`)
            return samples.data.payload
    }

    async getLivePrices(productIds, activeCustomerId, lite = false) {
        let products = await axios.post(`${this.apiUrl}/products/prices/list`, {
            productIds,
            activeCustomerId,
            lite
        })
        return products.data.payload
    }
}

export default Product

import React from 'react'
import { useTranslation } from "react-i18next";
import './ArchiveHeader.scss'

const ArchiveHeader = ({ page, perPage, title, itemsCount }) => {
    const { t } = useTranslation();

    return <header className="archive-header">
        <h1 className={ title === 'Samples Bazaar' && 'archive-header-center' }>{title} { title === 'Samples Bazaar' && <span className='archive-header-delivery-title'> {t('Minimum Order')}</span> }</h1>
        <div className="pangination-info">
            {t('Page')} <span className="active">{page}</span> {t('of')} {Math.ceil(itemsCount / perPage)}
        </div>
    </header>

}

export default ArchiveHeader
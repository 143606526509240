import Api from "../controllers/Api";

export const TYPES = {
    FETCH_SEASONS_SUCCESS: 'FETCH_SEASONS_SUCCESS',
    FETCH_SEASONS_FAILED: 'FETCH_SEASONS_FAILED',
    FETCH_ACTIVE_SEASONS_SUCCESS: 'FETCH_ACTIVE_SEASONS_SUCCESS',
    FETCH_ACTIVE_SEASONS_FAILED: 'FETCH_ACTIVE_SEASONS_FAILED',
    FETCH_SEASONS_PRESENTATION_SUCCESS: 'FETCH_SEASONS_PRESENTATION_SUCCESS',
    FETCH_SIMONE_SEASONS_PRESENTATION_SUCCESS: 'FETCH_SIMONE_SEASONS_PRESENTATION_SUCCESS',
    FETCH_SEASONS_PRESENTATION_FAILED: 'FETCH_SEASONS_PRESENTATION_FAILED',
}

export const getActiveSeasons = () => {
    return async (dispatch, getState) => {
        try {
            const seasons = await Api.season.getActive()

            dispatch({
                type: TYPES.FETCH_ACTIVE_SEASONS_SUCCESS,
                payload: seasons
            })
        } catch (e) {
            dispatch({
                type: TYPES.FETCH_ACTIVE_SEASONS_FAILED,
            })
        }
    }
}

export const getSeasons = () => {
    return async (dispatch, getState) => {
        try {
            const seasons = await Api.season.get()

            dispatch({
                type: TYPES.FETCH_SEASONS_SUCCESS,
                payload: seasons
            })
        } catch (e) {
            dispatch({
                type: TYPES.FETCH_SEASONS_FAILED,
            })
        }
    }
}

export const getPresentationSeasons = () => {
    return async (dispatch, getState) => {
        try {
            const seasons = await Api.season.getPresentation()
            dispatch({
                type: TYPES.FETCH_SEASONS_PRESENTATION_SUCCESS,
                payload: seasons
            })
        } catch (e) {
        }
    }
}

export const getSimonePresentationSeasons = () => {
    return async (dispatch, getState) => {
        try {
            const seasons = await Api.season.getSimonePresentation()
            dispatch({
                type: TYPES.FETCH_SIMONE_SEASONS_PRESENTATION_SUCCESS,
                payload: seasons
            })
        } catch (e) {
        }
    }
}
import React, {Component} from 'react'
import {connect} from 'react-redux'
import Api from '../../controllers/Api'
import AdminSection from "./AdminSection";
import FormElement from "../Forms/FormElement";
import Button from "../Button";
import Select from 'react-select'

import {saveSettings, saveHomepageSettings, saveHeaderSettings} from '../../actions/settingsActions'

import './AdminPage.scss'
import {Link} from "react-router-dom";

class AdminPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            families: [],
            users: [],
            seasonsSelection: [],
            secondEmailCountriesSelection: [],
            preorderSeasons: [],
            defaultSeason: null,
            defaultPresentationSeason: null,
            secondEmailCountries: [],
            preorderAllowedCountries: [],
            excludedFamilies: '',
            compareMainSeasons: [],
            comparePreviousSeasons: [],
            presentationSeasons: [],
            presentationSimoneSeasons: [],
            filterableSeasons: [],
            seasonOptions: [],
            samplesEnabled: false,
            siteEnabled: false,
            presentationEnabled: false,
            debugMode: false,
            companyEmail: '',
            orderCompany: '',
            orderSeries: '',
            homepage_image_1: '',
            homepage_image_2: '',
            simone_perele_buy_now_image: '',
            homepage_image_3: '',
            header_banner_image: '',
            header_banner_url: '',
            header_banner_image_Cz: '',
            header_banner_url_cz: '',
            popupImage: '',
            popupImageUrl: '',
            popupAllowedCountries: [],
            presentationBannersAllowed: [],
            archiveBanner: [],
            downloadLinks: [],
            loginBanner: ''
        }

        this.passwordInputs = []
        this.secondEmailInputs = []
    }


    prepareInitHomepageValues() {
        Api.settings.getHomepage().then(res => {
            this.setState({
                homepage_image_1: res.homepage_image_1,
                homepage_image_2: res.homepage_image_2,
                homepage_image_3: res.homepage_image_3,
                simone_perele_buy_now_image: res.simone_perele_buy_now_image
            })
        })
    }

    prepareInitHeaderValues() {
        Api.settings.getHeader().then(res => {
            this.setState({
                header_banner_image: res.header_banner_image,
                header_banner_url: res.header_banner_url,
                header_banner_image_cz: res.header_banner_image_cz,
                header_banner_url_cz: res.header_banner_url_cz,
            })
        })
    }

    prepareInitValues() {
        const {auth: {activeUser}, history, settings, countries} = this.props

        if (this.state.seasonOptions && this.state.seasonOptions.length || !this.props.seasons || !this.props.seasons.length || !this.props.countries.length) {
            return
        }

        const seasonOptions = this.prepareSeasonOptions(this.props.seasons)

        const secondEmailCountriesSelection = this.prepareSecondEmailCountries(this.props.countries)
        const secondEmailCountries = secondEmailCountriesSelection.filter(country => settings.secondEmailCountries.includes(country.value))
        const preorderAllowedCountries = secondEmailCountriesSelection.filter(country => settings.preorderAllowedCountries.includes(country.value))
        const compareMainSeasons = seasonOptions.filter(season => settings.compareMainSeasons.includes(season.value))
        const comparePreviousSeasons = seasonOptions.filter(season => settings.comparePreviousSeasons.includes(season.value))
        const defaultSeason = seasonOptions.filter(season => settings.defaultSeason == season.value)
        const defaultPresentationSeason = seasonOptions.filter(season => settings.defaultPresentationSeason == season.value)
        const preorderSeasons = seasonOptions.filter(season => settings.preorderSeasons.includes(season.value))
        const presentationSeasons = seasonOptions.filter(season => settings.presentationSeasons.includes(season.value))
        const presentationSimoneSeasons = seasonOptions.filter(season => settings.presentationSimoneSeasons.includes(season.value))
        const filterableSeasons = seasonOptions.filter(season => settings.filterableSeasons.includes(season.value))
        const popupAllowedCountries = secondEmailCountriesSelection.filter(country => settings.popupAllowedCountries.includes(country.value))

        const familiesSelect = this.props.seasons.map(season => {
            return season.families;
        }).filter((array) => array.length).reduce((a, b) => {
            return a.concat(...b)
        }).map((family) => {
            return {
                value: family.softoneCode,
                label: family.softoneCode,
            }
        });

        const presentationBannersAllowed = familiesSelect
            .filter(family => settings.presentationBannersAllowed.includes(family.value))
            .map(family => { return { value: family.value, label: family.value } });

        this.setState({
            familiesSelect,
            seasonOptions,
            compareMainSeasons,
            comparePreviousSeasons,
            defaultSeason,
            defaultPresentationSeason,
            preorderSeasons,
            secondEmailCountriesSelection,
            secondEmailCountries,
            preorderAllowedCountries,
            presentationSeasons,
            presentationSimoneSeasons,
            filterableSeasons,
            samplesEnabled: settings.samples.enabled,
            siteEnabled: settings.site.enabled,
            presentationEnabled: settings.presentation.enabled,
            excludedFamilies: settings.excludedFamilies,
            companyEmail: settings.companyEmail,
            orderCompany: settings.orderCompany,
            orderSeries: settings.orderSeries,
            debugMode: settings.debugMode,
            popupImage: settings.popupImage,
            popupImageUrl: settings.popupImageUrl,
            archiveBanner: settings.archiveBanner,
            downloadLinks: settings.downloadLinks,
            loginBanner: settings.loginBanner,
            popupAllowedCountries,
            presentationBannersAllowed,
        }, () => {
            Api.settings.getUsers().then((users) => {
                this.setState({
                    users
                })
            })
        })
    }

    componentDidMount() {
        const {auth: {activeUser}, history} = this.props

        if (!activeUser || activeUser.role !== 'admin') {
            history.push('/app')
        }

        this.prepareInitValues()
        this.prepareInitHeaderValues()
        this.prepareInitHomepageValues()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        this.prepareInitValues()
    }

    prepareSeasonOptions(seasons) {
        return seasons.map(season => {
            return {
                value: season.softoneId,
                label: season.name
            }
        })
    }

    prepareSecondEmailCountries(countries) {
        return countries.map(season => {
            return {
                value: season.softoneId,
                label: season.name
            }
        })
    }

    saveHeaderSettings(e) {
        e.preventDefault()
        const {saveHeaderSettings} = this.props;

        const {
            header_banner_image,
            header_banner_url,
            header_banner_image_cz,
            header_banner_url_cz,
        } = this.state;

        let settingsObject = {
            header_banner_image,
            header_banner_url,
            header_banner_image_cz,
            header_banner_url_cz,
        }

        saveHeaderSettings(settingsObject)
    }

    saveHomepageSettings(e) {
        e.preventDefault()
        const {saveHomepageSettings} = this.props;

        const {
            homepage_image_1,
            homepage_image_2,
            homepage_image_3,
            simone_perele_buy_now_image
        } = this.state;

        let settingsObject = {
            homepage_image_1,
            homepage_image_2,
            homepage_image_3,
            simone_perele_buy_now_image,
        }

        saveHomepageSettings(settingsObject)
    }

    saveGeneralSettings(e) {
        const {saveSettings, settings} = this.props
        const {
            seasonsSelection,
            defaultSeason,
            preorderSeasons,
            secondEmailCountries,
            preorderAllowedCountries,
            compareMainSeasons,
            comparePreviousSeasons,
            samplesEnabled,
            siteEnabled,
            presentationEnabled,
            excludedFamilies,
            companyEmail,
            orderCompany,
            orderSeries,
            debugMode,
            presentationSeasons,
            presentationSimoneSeasons,
            filterableSeasons,
            defaultPresentationSeason,
            popupImage,
            popupImageUrl,
            popupAllowedCountries,
            presentationBannersAllowed,
            archiveBanner,
            downloadLinks,
            loginBanner
        } = this.state
        e.preventDefault()

        let settingsObject = Object.assign({}, settings)

        settingsObject.site.enabled = siteEnabled
        settingsObject.samples.enabled = samplesEnabled
        settingsObject.presentation.enabled = presentationEnabled
        settingsObject.debugMode = debugMode
        settingsObject.archiveBanner = archiveBanner
        settingsObject.downloadLinks = downloadLinks
        settingsObject.loginBanner = loginBanner

        settingsObject.popupImage = popupImage
        settingsObject.popupImageUrl = popupImageUrl

        if (defaultSeason && defaultSeason.length) {
            settingsObject.defaultSeason = defaultSeason[0].value
        }

        if (defaultPresentationSeason && defaultPresentationSeason.length) {
            settingsObject.defaultPresentationSeason = defaultPresentationSeason[0].value
        }

        settingsObject.excludedFamilies = excludedFamilies
        settingsObject.companyEmail = companyEmail
        settingsObject.orderCompany = orderCompany
        settingsObject.orderSeries = orderSeries

        settingsObject.compareMainSeasons = compareMainSeasons.map(option => option.value)
        settingsObject.comparePreviousSeasons = comparePreviousSeasons.map(option => option.value)
        settingsObject.presentationSeasons = presentationSeasons.map(option => option.value)
        settingsObject.presentationSimoneSeasons = presentationSimoneSeasons.map(option => option.value)
        settingsObject.filterableSeasons = filterableSeasons.map(option => option.value)


        settingsObject.preorderSeasons = preorderSeasons.map(option => option.value)

        if (secondEmailCountries && secondEmailCountries.length) {
            settingsObject.secondEmailCountries = secondEmailCountries.map(option => option.value)
        } else {
            settingsObject.secondEmailCountries = []
        }

        if (preorderAllowedCountries && preorderAllowedCountries.length) {
            settingsObject.preorderAllowedCountries = preorderAllowedCountries.map(option => option.value)
        } else {
            settingsObject.preorderAllowedCountries = []
        }

        if (popupAllowedCountries && popupAllowedCountries.length) {
            settingsObject.popupAllowedCountries = popupAllowedCountries.map(option => option.value)
        } else {
            settingsObject.popupAllowedCountries = []
        }

        if (presentationBannersAllowed && presentationBannersAllowed.length) {
            settingsObject.presentationBannersAllowed = presentationBannersAllowed.map(option => option.value)
        } else {
            settingsObject.presentationBannersAllowed = []
        }

        saveSettings(settingsObject)
    }

    printUsersList() {
        const {users} = this.state

        return <table className="users-list">
            <tbody>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Active</th>
                <th>Password</th>
                <th>Extra Email Enabled</th>
            </tr>
            {users.map((user) => {
                this.passwordInputs.push({
                    userId: user._id,
                    ref: React.createRef()
                })

                this.secondEmailInputs.push({
                    userId: user._id,
                    ref: React.createRef()
                })

                return <tr key={user._id}>
                    <td className="user-name">{user.name}</td>
                    <td className="user-name">{user.email}</td>
                    <td className="user-name">{user.active ? 'ACTIVE' : 'DISABLED'}</td>
                    <td className="user-password-input">
                        <div className="td-container">
                            <input
                                minLength={6}
                                type="password"
                                placeholder="password.."
                                ref={this.passwordInputs.filter((item) => item.userId === user._id)[0].ref}
                            />
                            <Button title="Update" callback={() => {
                                const password = this.passwordInputs.filter((item) => item.userId === user._id)[0].ref.current.value

                                if (!password || password.trim().length < 6) {
                                    alert("Please enter a password with at least 6 characters")
                                } else {
                                    Api.settings.saveUserPassword(user._id, password.trim()).then(() => {
                                        alert('User password updated')
                                    })
                                }
                            }}/>
                        </div>
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            name="second-email-salesman"
                            defaultChecked={user.salesman[0].allowSecondEmail}
                            ref={this.secondEmailInputs.filter((item) => item.userId === user._id)[0].ref}
                            onChange={() => {
                                const value = this.secondEmailInputs.filter((item) => item.userId === user._id)[0].ref.current.checked
                                Api.settings.updateUserSecondEmail(user._id, value).then(res => {
                                    alert('User updated')
                                }).catch(err => {
                                    alert(`Error in updating user ${user.name} second email option`)
                                })
                            }}
                        />
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    }

    updateArchive( type, value ) {

        const tempArchiveBanner = this.state.archiveBanner

        if( type === 'GR-banner' ){

            tempArchiveBanner[0].bannerImageURL = value

        }

        else if( type === 'GR-banner-mobile' ){

            tempArchiveBanner[0].bannerImageURLMobile = value

        }

        else if( type === 'GR-banner-CTA' ){

            tempArchiveBanner[0].bannerCTAURL = value

        }

        else if( type === 'Simone-banner' ){
            tempArchiveBanner[2].bannerImageURL = value
        }
        else if( type === 'Simone-banner-mobile' ){
            tempArchiveBanner[2].bannerImageURLMobile = value
        }
        else if( type === 'Simone-banner-CTA' ){
            tempArchiveBanner[2].bannerCTAURL = value
        }

        else if( type === 'CZ-banner' ){

            tempArchiveBanner[1].bannerImageURL = value

        }

        else if( type === 'CZ-banner-mobile' ){

            tempArchiveBanner[1].bannerImageURLMobile = value

        }

        else if( type === 'CZ-banner-CTA' ){

            tempArchiveBanner[1].bannerCTAURL = value

        }


        this.setState({archiveBanner: tempArchiveBanner})


    }

    updateDownloadLink(index, value, type){
        const downloadLinksTemp = this.state.downloadLinks

        type === 'link' ? downloadLinksTemp[index].link = value : downloadLinksTemp[index].text = value

        this.setState({downloadLinks: downloadLinksTemp})
    }

    renderDownloadLinks = () => {

        return this.state.downloadLinks.map( (downloadLink, index ) => {

            return <>
                <FormElement label={'Download Text ' + ( index + 1 ) }>
                    <input type="text" name="order-email"
                           placeholder='Download Text'
                           value={downloadLink.text}
                           onChange={e => this.updateDownloadLink( index, e.target.value, 'text' )}
                    />
                    <input type="text" name="order-email"
                           placeholder='Download Link'
                           value={downloadLink.link}
                           onChange={e => this.updateDownloadLink( index, e.target.value, 'link' )}
                    />
                </FormElement>
            </>
        } )

    }

    render() {
        const {
            secondEmailCountriesSelection,
            preorderSeasons,
            secondEmailCountries,
            preorderAllowedCountries,
            compareMainSeasons,
            comparePreviousSeasons,
            defaultSeason,
            samplesEnabled,
            siteEnabled,
            presentationEnabled,
            excludedFamilies,
            companyEmail,
            orderCompany,
            orderSeries,
            debugMode,
            presentationSeasons,
            presentationSimoneSeasons,
            filterableSeasons,
            defaultPresentationSeason,
            popupImage,
            popupImageUrl,
            popupAllowedCountries,
            presentationBannersAllowed,
            familiesSelect,
            archiveBanner,
            downloadLinks,
            loginBanner
        } = this.state

        const {
            homepage_image_1,
            homepage_image_2,
            homepage_image_3,
            simone_perele_buy_now_image
        } = this.state

        const {
            header_banner_image,
            header_banner_url,
            header_banner_image_cz,
            header_banner_url_cz,
        } = this.state

        return (
            <div className="admin-page">
                <aside className="admin-menu-navigator">
                    <Link to="/app/admin/menu">
                        Menu Editor
                    </Link>
                </aside>
                <AdminSection title="General">
                    <form onSubmit={(e) => this.saveGeneralSettings(e)}>
                        <FormElement label="Samples Enabled">
                            <input type="checkbox" name="samples-enabled" checked={samplesEnabled} onChange={(e) => {
                                this.setState({samplesEnabled: e.target.checked})
                            }}/>
                        </FormElement>
                        <FormElement label="Site Enabled">
                            <input type="checkbox" name="site-enabled" checked={siteEnabled} onChange={(e) => {
                                this.setState({siteEnabled: e.target.checked})
                            }}/>
                        </FormElement>
                        <FormElement label="Presentation Enabled">
                            <input type="checkbox" name="presentation-enabled" checked={presentationEnabled}
                                   onChange={(e) => {
                                       this.setState({presentationEnabled: e.target.checked})
                                   }}/>
                        </FormElement>
                        <FormElement label="Excluded Families ( comma seperated )">
                            <input type="text" name="excluded-families" value={excludedFamilies}
                                   onChange={e => this.setState({excludedFamilies: e.target.value})}
                            />
                        </FormElement>
                        <FormElement label="Company Email">
                            <input type="email" name="order-email" value={companyEmail}
                                   onChange={e => this.setState({companyEmail: e.target.value})}
                            />
                        </FormElement>
                        <FormElement label="Order Company">
                            <input type="text" name="order-company" value={orderCompany}
                                   onChange={e => this.setState({orderCompany: e.target.value})}
                            />
                        </FormElement>
                        <FormElement label="Order Series">
                            <input type="text" name="order-series" value={orderSeries}
                                   onChange={e => this.setState({orderSeries: e.target.value})}
                            />
                        </FormElement>
                        <FormElement label="Debug Mode">
                            <input type="checkbox" name="debug-mode" checked={debugMode} onChange={(e) => {
                                this.setState({debugMode: e.target.checked})
                            }}/>
                        </FormElement>
                        <FormElement label="Preorder Allowed Countries">
                            <Select
                                value={preorderAllowedCountries}
                                onChange={(option) => {
                                    this.setState({
                                        preorderAllowedCountries: option
                                    })
                                }}
                                isMulti
                                name="presentation-preorder-allowed-countries"
                                options={secondEmailCountriesSelection}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormElement>
                        <FormElement label="Second Email Countries">
                            <Select
                                value={secondEmailCountries}
                                onChange={(option) => {
                                    this.setState({
                                        secondEmailCountries: option
                                    })
                                }}
                                isMulti
                                name="presentation-second-email-countries"
                                options={secondEmailCountriesSelection}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormElement>
                        <FormElement label="Preorder Seasons">
                            <Select
                                value={preorderSeasons}
                                onChange={(option) => {
                                    this.setState({
                                        preorderSeasons: option
                                    })
                                }}
                                isMulti
                                name="preorder-presentation-seasons"
                                options={this.state.seasonOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormElement>
                        <FormElement label="Presentation Seasons">
                            <Select
                                value={presentationSeasons}
                                onChange={(option) => {
                                    this.setState({
                                        presentationSeasons: option
                                    })
                                }}
                                isMulti
                                name="presentation-seasons"
                                options={this.state.seasonOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormElement>
                        <FormElement label="Simone Presentation Seasons">
                            <Select
                                value={presentationSimoneSeasons}
                                onChange={(option) => {
                                    this.setState({
                                        presentationSimoneSeasons: option
                                    })
                                }}
                                isMulti
                                name="presentation-simone-seasons"
                                options={this.state.seasonOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormElement>
                        <FormElement label="Default Season">
                            <Select
                                value={defaultSeason}
                                onChange={(option) => {
                                    this.setState({
                                        defaultSeason: [
                                            option
                                        ]
                                    })
                                }}
                                name="default-season"
                                options={this.state.seasonOptions}
                                className="basic-select"
                                classNamePrefix="select"
                            />
                        </FormElement>
                        <FormElement label="Default Presentation Season">
                            <Select
                                value={defaultPresentationSeason}
                                onChange={(option) => {
                                    this.setState({
                                        defaultPresentationSeason: [
                                            option
                                        ]
                                    })
                                }}
                                name="default-presentation-season"
                                options={this.state.seasonOptions}
                                className="basic-select"
                                classNamePrefix="select"
                            />
                        </FormElement>
                        <FormElement label="Compare main seasons select">
                            <Select
                                value={compareMainSeasons}
                                onChange={(option) => {
                                    this.setState({
                                        compareMainSeasons: option
                                    })
                                }}
                                name="compare-main-seasons"
                                options={this.state.seasonOptions}
                                className="basic-select"
                                classNamePrefix="select"
                                isMulti
                            />
                        </FormElement>
                        <FormElement label="Compare previous seasons select">
                            <Select
                                value={comparePreviousSeasons}
                                onChange={(option) => {
                                    this.setState({
                                        comparePreviousSeasons: option
                                    })
                                }}
                                name="compare-previous-seasons"
                                options={this.state.seasonOptions}
                                className="basic-select"
                                classNamePrefix="select"
                                isMulti
                            />
                        </FormElement>
                        <FormElement label="Select order filter seasons">
                            <Select
                                value={filterableSeasons}
                                onChange={(option) => {
                                    this.setState({
                                        filterableSeasons: option
                                    })
                                }}
                                name="select-order-filter-seasons"
                                options={this.state.seasonOptions}
                                className="basic-select"
                                classNamePrefix="select"
                                isMulti
                            />
                        </FormElement>
                        <FormElement label="Products Page Popup Image">
                            <input type="text" name="order-email" value={popupImage}
                                   onChange={e => this.setState({popupImage: e.target.value})}
                            />
                        </FormElement>
                        <FormElement label="Products Page Popup Image URL">
                            <input type="text" name="order-email" value={popupImageUrl}
                                   onChange={e => this.setState({popupImageUrl: e.target.value})}
                            />
                        </FormElement>
                        <FormElement label="Popup Allowed Countries">
                            <Select
                                value={popupAllowedCountries}
                                onChange={(option) => {
                                    this.setState({
                                        popupAllowedCountries: option
                                    })
                                }}
                                isMulti
                                name="presentation-preorder-allowed-countries"
                                options={secondEmailCountriesSelection}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormElement>

                        <FormElement label="Presentation Banners Apply">
                            <Select
                                value={presentationBannersAllowed}
                                onChange={(option) => {
                                    this.setState({
                                        presentationBannersAllowed: option
                                    })
                                }}
                                isMulti
                                name="presentation-preorder-allowed-countries"
                                options={familiesSelect}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormElement>
                        <FormElement label="Buy Now GR Banner Image URL">
                            <input type="text" name="order-email" value={archiveBanner[0] ? archiveBanner[0].bannerImageURL : ''}
                                   onChange={e => this.updateArchive('GR-banner', e.target.value )}
                            />
                        </FormElement>
                        <FormElement label="Buy Now GR Banner Mobile Image URL">
                            <input type="text" name="order-email" value={archiveBanner[0] ? archiveBanner[0].bannerImageURLMobile : ''}
                                   onChange={e => this.updateArchive('GR-banner-mobile', e.target.value )}
                            />
                        </FormElement>
                        <FormElement label="Buy Now GR Banner CTA Link">
                            <input type="text" name="order-email" value={archiveBanner[0] ? archiveBanner[0].bannerCTAURL : ''}
                                   onChange={e => this.updateArchive('GR-banner-CTA', e.target.value )}
                            />
                        </FormElement>
                        <FormElement label="Buy Now CZ Banner Image URL">
                            <input type="text" name="order-email" value={archiveBanner[1] ? archiveBanner[1].bannerImageURL : ''}
                                   onChange={e => this.updateArchive('CZ-banner', e.target.value )}
                            />
                        </FormElement>
                        <FormElement label="Buy Now CZ Banner Mobile Image URL">
                            <input type="text" name="order-email" value={archiveBanner[1] ? archiveBanner[1].bannerImageURLMobile : ''}
                                   onChange={e => this.updateArchive('CZ-banner-mobile', e.target.value )}
                            />
                        </FormElement>
                        <FormElement label="Buy Now CZ Banner CTA Link">
                            <input type="text" name="order-email" value={archiveBanner[1] ? archiveBanner[1].bannerCTAURL : ''}
                                   onChange={e => this.updateArchive('CZ-banner-CTA', e.target.value )}
                            />
                        </FormElement>
                        <FormElement label="Buy Now Simone Banner Image URL">
                            <input type="text" name="order-email" value={archiveBanner[2] ? archiveBanner[2].bannerImageURL : ''}
                                   onChange={e => this.updateArchive('Simone-banner', e.target.value )}
                            />
                        </FormElement>
                        <FormElement label="Buy Now Simone Banner Mobile Image URL">
                            <input type="text" name="order-email" value={archiveBanner[2] ? archiveBanner[2].bannerImageURLMobile : ''}
                                   onChange={e => this.updateArchive('Simone-banner-mobile', e.target.value )}
                            />
                        </FormElement>
                        <FormElement label="Buy Now Simone Banner CTA Link">
                            <input type="text" name="order-email" value={archiveBanner[2] ? archiveBanner[2].bannerCTAURL : ''}
                                   onChange={e => this.updateArchive('Simone-banner-CTA', e.target.value )}
                            />
                        </FormElement>
                        <FormElement label="Login Banner URL">
                            <input type="text" name="order-email" value={loginBanner}
                                   onChange={e => this.setState({loginBanner: e.target.value})}
                            />
                        </FormElement>
                        {this.renderDownloadLinks()}
                        <FormElement>
                            <button type="submit">Save</button>
                        </FormElement>
                    </form>
                </AdminSection>
                <AdminSection title="Users">
                    {this.printUsersList()}
                </AdminSection>
                <AdminSection title="Homepage">
                    <form onSubmit={(e) => this.saveHomepageSettings(e)}>
                        <FormElement label="Homepage image 1">
                            <input type="text" name="homepage_image_1" value={homepage_image_1}
                                   onChange={e => this.setState({homepage_image_1: e.target.value})}
                            />
                        </FormElement>
                        <FormElement label="Homepage image 2">
                            <input type="text" name="homepage_image_2" value={homepage_image_2}
                                   onChange={e => this.setState({homepage_image_2: e.target.value})}
                            />
                        </FormElement>
                        <FormElement label="Simone Perele Buy Now Image">
                            <input type="text" name="simone_perele_buy_now_image" value={simone_perele_buy_now_image}
                                   onChange={e => this.setState({simone_perele_buy_now_image: e.target.value})}
                            />
                        </FormElement>
                        <FormElement label="Homepage image 3">
                            <input type="text" name="homepage_image_3" value={homepage_image_3}
                                   onChange={e => this.setState({homepage_image_3: e.target.value})}
                            />
                        </FormElement>
                        <FormElement>
                            <button type="submit">Save</button>
                        </FormElement>
                    </form>
                </AdminSection>
                <AdminSection title="Header">
                    <form onSubmit={(e) => this.saveHeaderSettings(e)}>
                        <FormElement label="Header Banner Image GR">
                            <input type="text" name="header_banner_image" value={header_banner_image}
                                   onChange={e => this.setState({header_banner_image: e.target.value})}
                            />
                        </FormElement>
                        <FormElement label="Header Banner URL GR">
                            <input type="text" name="header_banner_url" value={header_banner_url}
                                   onChange={e => this.setState({header_banner_url: e.target.value})}
                            />
                        </FormElement>
                        <FormElement label="Header Banner Image CZ">
                            <input type="text" name="header_banner_image_cz" value={header_banner_image_cz}
                                   onChange={e => this.setState({header_banner_image_cz: e.target.value})}
                            />
                        </FormElement>
                        <FormElement label="Header Banner URL CZ">
                            <input type="text" name="header_banner_url_cz" value={header_banner_url_cz}
                                   onChange={e => this.setState({header_banner_url_cz: e.target.value})}
                            />
                        </FormElement>
                        <FormElement>
                            <button type="submit">Save</button>
                        </FormElement>
                    </form>
                </AdminSection>
            </div>
        );
    }
}

const mapStateToProps = ({auth, settings, season, customer}) => {

    return {
        auth,
        seasons: season.seasons,
        settings: settings.settings,
        countries: customer.countries
    }
}

export default connect(mapStateToProps, {saveSettings, saveHomepageSettings, saveHeaderSettings})(AdminPage)

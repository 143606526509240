import React, { useState } from 'react'
import {Link} from "react-router-dom";
import {ReactComponent as CloseSvg} from '../../assets/close.svg';
import {ReactComponent as AngleRight} from '../../assets/angle-right.svg';
import { useTranslation } from "react-i18next";

import './ArchiveMenu.scss'

const printArchiveMenu = (menuItems, level = 0, activeItems, setActiveItems) => {

    if(!menuItems || !menuItems.length) {
        return null
    }

    return <ul className={`${level > 0 ? 'submenu' : ''}`}>
        {menuItems.map(item => {

            if(!item.subtitle || !item.title) {
                return null
            }

            const itemKey = `${item.title}-${level}`

            return <li key={itemKey} className={`${item.title === 'Offers' ? 'offers-menu' : '' }  ${activeItems.includes(itemKey) ? 'show-submenu' : ''}`}>
                <div className="link-wrapper">
                    <Link to={item.subtitle}>
                        {item.title}
                    </Link>
                    {item.children && item.children.length ?
                        <AngleRight onClick={() => {
                            let tempItems = [...activeItems]

                            if(tempItems.includes(itemKey)) {
                                tempItems = tempItems.filter(item => item !== itemKey)
                            }else {
                                tempItems.push(itemKey)
                            }

                            setActiveItems(tempItems)
                        }} />
                        :
                        null
                    }
                </div>
                {item.children && item.children.length ? printArchiveMenu(item.children, level + 1, activeItems, setActiveItems) : null}
            </li>
        })}
    </ul>

}

const ArchiveMenu = ({ menuItems }) => {
    const { t } = useTranslation();

    const [ activeItems, setActiveItems ] = useState([]);
    const [ mobileMenuVisible, setMobileMenuVisible ] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('factory');

    return <aside className={`archive-menu-navigator ${mobileMenuVisible ? 'show-mobile-categories-menu' : ''}`}>
        <><div className="mobile-archive-menu-toggle" onClick={() => setMobileMenuVisible(!mobileMenuVisible)}>
            <span className="vamp-button">{t('Categories')}</span>
        </div>
            <div className="menu-wrapper-container">
                <div className="menu-wrapper">
                    <div className="mobile-archive-menu-close">
                        <CloseSvg onClick={() => setMobileMenuVisible(false)}/>
                    </div>
                    <div className="menu-bg"></div>
                    {printArchiveMenu(menuItems, 0, activeItems, setActiveItems)}
                </div>
            </div>
        </>
        {myParam === 'Simone' ? <img className='simone-logo-img' src='/simoneLogo.svg'/> : ''}
    </aside>
}

export default ArchiveMenu
import React, { Fragment } from 'react'
import ColorsList from "./ColorsList";
import SizesList from "./SizesList";
import Button from "../Button";
import { useTranslation } from "react-i18next";

const findColorsAndSizes = (product) => {
    let sizes = new Set()
    let colors = {}

    for (let i = 0; i < product.variations.length; i++) {
        sizes.add(product.variations[i].sizeName)

        if (colors[product.variations[i].colorId]) {
            continue;
        }

        if( product.factory === 'Simone' ){
            const selectedSeason = localStorage.getItem("selectedSeason");
            if( selectedSeason !== product.variations[i].season && product.variations[i].season !== "0000" ){
                continue;
            }
        }

        colors[product.variations[i].colorId] = {
            name: product.variations[i].colorName,
            hex: product.variations[i].colorHex,
            code: product.variations[i].colorCode,
        }
    }

    return {
        sizes: Array.from(sizes),
        colors: Object.values(colors)
    }
}

const ProductMeta = ({ extended, product, openQuickViewAction, currencySymbol, lowestPrice, selectedColor, setSelectedColor }) => {
    const { t } = useTranslation();

    const isSimonePreorder = product.factory === 'Simone';

    //Find colors and sizes
    const {colors, sizes} = findColorsAndSizes(product)

    return <div className="product-meta">
        <div className="product-code">
            {product.code}{ isSimonePreorder ? ( ' - ' + product.titleEN ) : '' }
        </div>
        {product.fabric ?
            <div className="product-fabric">
                {product.fabric}
            </div>
            :
            null
        }
        {(product.length && product.front) ? <div className="product-info">
            <span>{product.length}cm</span> | <span>{product.front}</span>
        </div> : null}
        <div className="product-colors">
            <ColorsList selectedColor={selectedColor} setSelectedColor={setSelectedColor} colors={colors}/>
        </div>
        {
            isSimonePreorder ? '' : <div className="product-sizes">
                <SizesList sizes={sizes}/>
            </div>
        }
        {extended ?
            <Fragment>
                <div className="product-price">
                    {currencySymbol}{lowestPrice}
                </div>
                <div className="product-quickview-button" onClick={() => openQuickViewAction(product._id)}>
                    <Button className="single-product-add-to-cart" title={t('Add to Order')}></Button>
                </div>
            </Fragment>
            : null}
    </div>
}

export default ProductMeta
import React, {Component} from 'react'
import Api from '../../../controllers/Api'
import queryString from "query-string";
import * as _ from "loadsh";
import {connect} from "react-redux";
import $ from 'jquery';


import ProductsList from "../../Product/ProductsList";


import {getCurrencySymbol, getLivePrices, checkIfCustomerHasLocalPrices} from "../../../utils/priceUtils";
import {wishlistToggleAction} from "../../../actions/wishlistActions";
import {setLastVisitedProduct} from "../../../actions/productAction";
import ArchiveMenu from "../../Common/Archive/ArchiveMenu";

import './Archive.scss'
import Cookies from "universal-cookie";

class ArchivePage extends Component {

    constructor(props) {
        super(props);

        this.cookies = new Cookies();
        this.isBuyNowPage = props.location && props.location.pathname === '/app/archive';

        //Check for query strings
        const {filtersNormalized, page, sort} = this.parseFilters()

        this.debouncedFetchProducts = _.debounce(this.fetchProducts, 500)
        this.debouncedFetchLivePrices = _.debounce(this.fetchLivePrices, 900)

        //Live prices is an object!
        this.initArchiveState = {
            products: [],
            livePrices: [],
            filters: {},
            fetchingProducts: true,
            fetchedLivePrices: false,
            priceRange: {
                minPrice: 1,
                maxPrice: 99999,
            },
            productsCount: 0,
            customerHasLocalPrices: false,
        }

        this.state = {
            ...this.initArchiveState,
            activeFilters: filtersNormalized,
            postsPerPage: 24,
            page: page,
            sort: sort,
        }
    }

    componentDidMount() {
        //Get archive products
        const {activeFilters, page, sort} = this.state
        this.debouncedFetchProducts(activeFilters, page, '', sort)

        const script = document.createElement("script");
        script.src = "https://interactive-img.com/js/include.js";
        script.async = true;
        document.body.appendChild(script);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const {settings} = this.props.settings;
        const {activeUser, activeCustomer} = this.props;

        const popupImage = settings.popupImage && settings.popupImage.length ? settings.popupImage : false;
        const popupAllowedCountries = settings.popupAllowedCountries && settings.popupAllowedCountries.length ? settings.popupAllowedCountries : [];

        if (activeUser && this.isBuyNowPage && popupImage && !this.cookies.get(`archive_popup_shown_${activeUser.id}`)) {
            if(activeCustomer && activeCustomer.customer && popupAllowedCountries.includes(activeCustomer.customer[0].billingAddress.softoneCountryCode.toString())) {
                setTimeout(() => {
                    $('.popup_image').click();

                    let d = new Date();
                    d.setTime(d.getTime() + (60 * 60 * 1000)); //1 hour
                    this.cookies.set(`archive_popup_shown_${activeUser.id}`, true, {path: "/", expires: d});
                }, 3000)
            }
        }

        //Check if we have to get live prices
        if (
            !this.state.livePrices.length &&
            this.state.products.length &&
            !this.state.fetchedLivePrices &&
            this.props.activeCustomer
        ) {

            const {activeCustomer} = this.props
            let products = JSON.parse(JSON.stringify(this.state.products))

            //Check if we have to get local prices
            const customerHasLocalPrices = checkIfCustomerHasLocalPrices(activeCustomer)

            if (!customerHasLocalPrices) {
                //Live prices for PRICEW01, PRICEW02
                this.debouncedFetchLivePrices(products, activeCustomer)
            }

            this.setState({
                customerHasLocalPrices,
                fetchedLivePrices: true
            })
        }

        //Check if filters changed
        const {filtersNormalized, page, sort} = this.parseFilters()

        const transformedActiveFilters = {}
        Object.keys(this.state.activeFilters).filter(key => {
            transformedActiveFilters[key] = Array.from(this.state.activeFilters[key])
        })

        const transformedFiltersNormalized = {}
        Object.keys(filtersNormalized).filter(key => {
            transformedFiltersNormalized[key] = Array.from(filtersNormalized[key])
        })

        if (
            JSON.stringify(transformedActiveFilters) !== JSON.stringify(transformedFiltersNormalized) ||
            this.state.page != page
        ) {
            this.setState({
                activeFilters: filtersNormalized,
                page,
                sort: sort
            }, () => {
                this.debouncedFetchProducts(filtersNormalized, page, '', sort)
            })
        }

        //Detect change on families
        if (this.props.familyId && this.props.familyId !== prevProps.familyId) {
            this.debouncedFetchProducts(filtersNormalized, 1, '', sort)
        }

        //Detect change in wishlist
        if (
            this.props.wishlist &&
            this.props.wishlistItems &&
            this.props.wishlistItems.length &&
            prevProps.wishlistItems.length &&
            JSON.stringify(this.props.wishlistItems) !== JSON.stringify(prevProps.wishlistItems)
        ) {
            this.debouncedFetchProducts(filtersNormalized, 1, '', sort)
        }
        const script = document.createElement("script");
        script.src = "https://interactive-img.com/js/include.js";
        script.async = true;
        document.body.appendChild(script);
    }

    parseFilters() {
        const {history} = this.props

        const urlParams = new URLSearchParams(window.location.search);
        const factoryParam = urlParams.get('factory');

        let params = queryString.parse(history.location.search)
        let page = 1;
        let sort = factoryParam === 'Simone' ? 'sku' : 'default';
        let activeFilters = {};

        if (params) {
            if (params.page) {
                page = params.page
            }

            if (params.sort) {
                sort = params.sort
            }

            if (params.filters) {
                activeFilters = JSON.parse(params.filters)
            }
        }

        //Convert filters to set
        let filtersNormalized = {}

        if (activeFilters && Object.keys(activeFilters) && Object.keys(activeFilters).length) {
            Object.keys(activeFilters).filter(key => {
                filtersNormalized[key] = new Set(activeFilters[key])
            })
        }

        return {
            filtersNormalized,
            page,
            sort
        }
    }

    fetchLivePrices(products, customer) {
        const self = this
        while (products.length) {
            const productsPayload = products.splice(0, 12)
            getLivePrices(productsPayload, customer).then(prices => {
                this.setState({
                    livePrices: self.state.livePrices.concat(prices)
                })
            })
        }
    }

    fetchProducts(filters = {}, page = 1, inputTerm = '', sort) {
        //Component props check
        //If preorders
        const isPreorder = this.props.preorder
        const preorderSeasonId = this.props.preorderSeasonId
        const isWishlist = this.props.wishlist

        //Has family
        const familyId = this.props.familyId

        //Check for coming soon and disabled buy flags
        const disabledBuy = this.props.disabledBuy
        const comingSoonProducts = this.props.comingSoonProducts

        this.setState({
            ...this.initArchiveState
        })

        //na valoume to preorder season

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('factory');

        Api.product.getList(
            filters,
            page,
            inputTerm,
            isPreorder,
            preorderSeasonId,
            familyId,
            sort,
            isWishlist,
            {
                comingSoonProducts
            },
            myParam
        ).then(productsData => {
            const tempProductList = isWishlist ? productsData.products.filter( tempProduct => tempProduct.markedAsPreorder === true ) : productsData.products

            const tempProductListWithFactory = myParam === 'Simone' ? tempProductList.filter( tempProduct => tempProduct.factory === 'Simone' ) : tempProductList.filter( tempProduct => tempProduct.factory === 'Vamp' )

            this.setState({
                products: tempProductListWithFactory,
                filters: productsData.filters,
                priceRange: productsData.priceRange,
                productsCount: productsData.productsCount,
                fetchingProducts: false,
                page
            })
        }).catch(error => {
            console.log(error.response)
        })
    }

    render() {
        const {
            history,
            activeCustomer,
            activeUser,
            settings,
            preorder,
            wishlistToggleAction,
            wishlistItems,
            wishlist,
            hideNavigation,
            prevFamily,
            nextFamily,
            disabledBuy,
            comingSoonProducts,
            setLastVisitedProduct,
        } = this.props

        const {
            products,
            livePrices,
            priceRange,
            filters,
            postsPerPage,
            productsCount,
            activeFilters,
            fetchingProducts,
            page,
            sort,
            customerHasLocalPrices,
        } = this.state

        let currencySymbol = getCurrencySymbol(activeUser)
        let customerRole = null

        if (activeCustomer) {
            currencySymbol = getCurrencySymbol(activeCustomer)
            customerRole = activeCustomer.role
        }

        let showFiltersFlag = true

        if (comingSoonProducts) {
            showFiltersFlag = false
        }

        if (this.props.general.lastVisitedProductId && products && products.length) {
            const lastVisitedElement = document.querySelector(`.product-item-${this.props.general.lastVisitedProductId}`);

            if (lastVisitedElement) {
                lastVisitedElement.scrollIntoView();
                setLastVisitedProduct(null)
            }
        }

        const popupImage = settings.settings.popupImage && settings.settings.popupImage.length ? settings.settings.popupImage : false;
        const popupImageUrl = settings.settings.popupImageUrl && settings.settings.popupImageUrl.length ? settings.settings.popupImageUrl : false;

        //const archiveBanner = settings.settings.archiveBanner.filter(banner => banner.B2BView === activeUser.b2bView)

        const urlParams = new URLSearchParams(window.location.search);
        const factoryParam = urlParams.get('factory');

        let menuTemp = factoryParam === 'Simone' ? settings.settings.menuItemsSimone : ( activeUser.b2bView === 'GR' ? settings.settings.menuItems : settings.settings.menuItemsCZ )

        const archiveBanner = factoryParam === 'Simone' ? settings.settings.archiveBanner.filter(banner => banner.B2BView === 'Simone') : settings.settings.archiveBanner.filter(banner => banner.B2BView === activeUser.b2bView)

        return <div className="archive-page">

            {popupImage &&
                <div data-fancybox={true} className="popup_image" style={{display: 'none'}}>
                    {popupImageUrl ?
                        <a href={popupImageUrl} onClick={() => window.open(popupImageUrl,'_blank') }>
                            <img src={popupImage} alt="popup"/>
                        </a>
                        :
                        <img src={popupImage} alt="popup"/>
                    }
                </div>
            }

            {!preorder && !hideNavigation ? <ArchiveMenu menuItems={menuTemp}/> : null}
            {comingSoonProducts ?
                <div className="coming-soon-header-container">
                    {/*<header className="coming-soon-header">Coming Soon</header>*/}
                    <p>
                        Available to order in a few days
                    </p>
                </div>
                : null}
            {
                history.location.pathname === '/app/archive' && archiveBanner.length && archiveBanner[0].active &&
                (
                    ( archiveBanner[0].bannerImageURL && archiveBanner[0].bannerImageURL.length < 30 ) ?
                        <>
                            <div className="iactiveImg banner-desktop-dynamic-banner" data-ii={archiveBanner[0].bannerImageURL}></div>
                            <div className="iactiveImg banner-mobile-dynamic-banner" data-ii={archiveBanner[0].bannerImageURLMobile}></div>
                        </> :
                        <a href={archiveBanner[0].bannerCTAURL}>
                            <div className={'banner-header'}>
                                { archiveBanner[0].bannerImageURL && <img className={'banner-desktop'} src={archiveBanner[0].bannerImageURL} alt="header banner"/>}
                                { archiveBanner[0].bannerImageURLMobile && <img className={'banner-mobile'} src={archiveBanner[0].bannerImageURLMobile} alt="header banner"/> }
                            </div>
                        </a>
                )
            }
            <ProductsList
                disabledBuy={disabledBuy}
                comingSoonProducts={comingSoonProducts}
                wishlist={wishlist}
                preorder={preorder}
                customerHasLocalPrices={customerHasLocalPrices}
                customerRole={customerRole}
                sortBySeason={true}
                extraMeta={true}
                showFilters={showFiltersFlag}
                entries={products}
                page={page}
                sort={sort}
                livePrices={livePrices}
                filters={filters}
                fetchProductsAction={(filters, page, inputTerm) => this.debouncedFetchProducts(filters, page, inputTerm, sort)}
                priceRange={priceRange}
                productsCount={productsCount}
                fetchingProducts={fetchingProducts}
                // variationPrices={variationPrices}
                currencySymbol={currencySymbol}
                // activeCustomer={activeCustomer}
                history={history}
                postsPerPage={postsPerPage}
                wishlistItems={wishlistItems}
                activeUser={activeUser}
                wishlistToggle={wishlistToggleAction}
                showBottomNavigation={false}
                prevFamily={prevFamily}
                nextFamily={nextFamily}
                activeFiltersFromParams={activeFilters}
                changePage={(page) => {
                    this.setState({page})
                }}
                changeSort={(sort) => {
                    this.setState({sort})
                }}
            />
        </div>
    }
}

const mapStateToProps = ({settings, product, season, wishlist, customer, auth, general}) => {
    return {
        activeCustomer: customer.activeCustomer,
        activeUser: auth.activeUser,
        wishlistItems: wishlist.items.products,
        settings: settings,
        general: general
    }
}

export default connect(mapStateToProps, {wishlistToggleAction, setLastVisitedProduct})(ArchivePage)

import React from 'react'
import './FamilyItem.scss'
import WishlistButton from "../../Product/WishlistButton";

const FamilyItem = ({item, handleClick, wishlistToggle, isInWishlist}) => {

    const urlParams = new URLSearchParams(window.location.search);
    const factoryParam = urlParams.get('factory');
    const isSimonePreorder = factoryParam === 'Simone';

    return <article className="family-item">
        {/*<WishlistButton*/}
        {/*    itemId={item._id}*/}
        {/*    handleWishlistClick={() => wishlistToggle(item._id, 'family')}*/}
        {/*    isInWishlist={isInWishlist}*/}
        {/*    icon='like'*/}
        {/*/>*/}
        <img src={item.image} alt={item.name} onClick={() => handleClick(item._id)}/>
        <div className="family-friendly-name">
            {item.friendlyName}
        </div>
        <div className="name">
            {isSimonePreorder ? item.softoneCode : item.name}
        </div>
        {/* item.collectionName === "HOMEWEAR" && <img className={'web-only-svg'} src='/webonly.svg'/> */ }
    </article>
}

export default FamilyItem
